import auth from "@/middlewares/auth";
import guest from "@/middlewares/guest";

import { formRoutes } from "@/routes/formRoutes";

let routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard'),
    name: 'dashboard',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/settings',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Settings'),
    name: 'settings',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/logs',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/logs/Index.vue'),
    name: 'logs',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/auth/Login'),
    name: 'login',
    meta: {
      middleware: [
        guest
      ]
    }
  },
]

routes.push(...formRoutes);

export default routes;
