<template>
  <div class="modal fade" id="modal-settings">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Profile</h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" v-if="form">
          <div class="form-group">
            <input type="text"
                   :disabled="loading"
                   v-model="form.clinic_name"
                   placeholder="Clinic Name"
                   :class="['form-control', {'is-invalid': errorFor('clinic_name')}]">
            <v-errors :errors="errorFor('clinic_name')" />
          </div>

          <div class="form-group">
            <input type="text"
                   :disabled="loading"
                   v-model="form.clinic_address"
                   placeholder="Clinic Address"
                   :class="['form-control', {'is-invalid': errorFor('clinic_address')}]">
            <v-errors :errors="errorFor('clinic_address')" />
          </div>

          <div class="form-group">
            <input type="text"
                   :disabled="loading"
                   v-model="form.clinic_phone"
                   placeholder="Clinic Phone"
                   :class="['form-control', {'is-invalid': errorFor('clinic_phone')}]">
            <v-errors :errors="errorFor('clinic_phone')" />
          </div>

          <div class="form-group">
            <input type="text"
                   :disabled="loading"
                   v-model="form.clinic_email"
                   placeholder="Clinic Email"
                   :class="['form-control', {'is-invalid': errorFor('clinic_email')}]">
            <v-errors :errors="errorFor('clinic_email')" />
          </div>

          <div class="form-group">
            <input type="text"
                   :disabled="loading"
                   v-model="form.google_folder_id"
                   placeholder="Google Folder Link"
                   :class="['form-control', {'is-invalid': errorFor('google_folder_id')}]">
            <v-errors :errors="errorFor('google_folder_id')" />
          </div>

          <div class="card">
            <div class="card-body">
              <ul class="nav nav-pills nav-justified" role="tablist">
                <li class="nav-item waves-effect waves-light">
                  <a class="nav-link active"
                     data-bs-toggle="tab"
                     href="#intakeSettings"
                     role="tab"
                     aria-selected="true">Intake</a>
                </li>
                <li class="nav-item waves-effect waves-light">
                  <a class="nav-link"
                     data-bs-toggle="tab"
                     href="#noteSettings"
                     role="tab"
                     aria-selected="false">Note</a>
                </li>
              </ul>

              <!-- Tab panes -->
              <div class="tab-content">
                <div class="tab-pane active" id="intakeSettings" role="tabpanel">
                  <div class="row">
                    <div class="col-6">
                      <h6 class="text-center">Header</h6>
                      <ul class="list-group list-group-flush border">
                        <li class="list-group-item">
                          <div class="form-check">
                            <input class="form-check-input"
                                   v-model="form.header_intake_clinic_name"
                                   type="checkbox">
                            <label class="form-check-label">
                              Clinic Name
                            </label>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="form-check">
                            <input class="form-check-input"
                                   v-model="form.header_intake_clinic_address"
                                   type="checkbox">
                            <label class="form-check-label">
                              Clinic Address
                            </label>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="form-check">
                            <input class="form-check-input"
                                   v-model="form.header_intake_clinic_phone"
                                   type="checkbox">
                            <label class="form-check-label">
                              Clinic Phone
                            </label>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="form-check">
                            <input class="form-check-input"
                                   v-model="form.header_intake_clinic_email"
                                   type="checkbox">
                            <label class="form-check-label">
                              Clinic Email
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="col-6">
                      <h6 class="text-center">Footer</h6>
                      <ul class="list-group list-group-flush border">
                        <li class="list-group-item">
                          <div class="form-check">
                            <input class="form-check-input"
                                   v-model="form.footer_intake_clinic_name"
                                   type="checkbox">
                            <label class="form-check-label">
                              Clinic Name
                            </label>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="form-check">
                            <input class="form-check-input"
                                   v-model="form.footer_intake_clinic_address"
                                   type="checkbox">
                            <label class="form-check-label">
                              Clinic Address
                            </label>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="form-check">
                            <input class="form-check-input"
                                   v-model="form.footer_intake_clinic_phone"
                                   type="checkbox">
                            <label class="form-check-label">
                              Clinic Phone
                            </label>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="form-check">
                            <input class="form-check-input"
                                   v-model="form.footer_intake_clinic_email"
                                   type="checkbox">
                            <label class="form-check-label">
                              Clinic Email
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="tab-pane" id="noteSettings" role="tabpanel">
                  <div class="row">
                    <div class="col-6">
                      <h6 class="text-center">Header</h6>
                      <ul class="list-group list-group-flush border">
                        <li class="list-group-item">
                          <div class="form-check">
                            <input class="form-check-input"
                                   v-model="form.header_note_clinic_name"
                                   type="checkbox">
                            <label class="form-check-label">
                              Clinic Name
                            </label>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="form-check">
                            <input class="form-check-input"
                                   v-model="form.header_note_clinic_address"
                                   type="checkbox">
                            <label class="form-check-label">
                              Clinic Address
                            </label>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="form-check">
                            <input class="form-check-input"
                                   v-model="form.header_note_clinic_phone"
                                   type="checkbox">
                            <label class="form-check-label">
                              Clinic Phone
                            </label>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="form-check">
                            <input class="form-check-input"
                                   v-model="form.header_note_clinic_email"
                                   type="checkbox">
                            <label class="form-check-label">
                              Clinic Email
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="col-6">
                      <h6 class="text-center">Footer</h6>
                      <ul class="list-group list-group-flush border">
                        <li class="list-group-item">
                          <div class="form-check">
                            <input class="form-check-input"
                                   v-model="form.footer_note_clinic_name"
                                   type="checkbox">
                            <label class="form-check-label">
                              Clinic Name
                            </label>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="form-check">
                            <input class="form-check-input"
                                   v-model="form.footer_note_clinic_address"
                                   type="checkbox">
                            <label class="form-check-label">
                              Clinic Address
                            </label>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="form-check">
                            <input class="form-check-input"
                                   v-model="form.footer_note_clinic_phone"
                                   type="checkbox">
                            <label class="form-check-label">
                              Clinic Phone
                            </label>
                          </div>
                        </li>
                        <li class="list-group-item">
                          <div class="form-check">
                            <input class="form-check-input"
                                   v-model="form.footer_note_clinic_email"
                                   type="checkbox">
                            <label class="form-check-label">
                              Clinic Email
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
          <button type="button"
                  @click.prevent="save"
                  class="btn btn-primary float-end">Save changes</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      form: null,
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('modal-settings'));
  },
  methods: {
    show() {
      this.form = {...this.$store.state.settings.settings}
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    save() {
      this.$store.dispatch('settings/saveSettings', this.form)
        .then(() => {
          this.hide();
        })
    }
  }
}
</script>